import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(theme => ({

    colorTextSecondary: {
        paddingTop: '2%',
        fontSize: '1.5rem',
        color: '#C45824',
        fontWeight: 500,
        '@media (max-width:570px)': {
            textAlign: 'center'
        },

    },

    frameColor: {
        backgroundColor: '#C45824',
        marginTop: '2%',
        marginBottom: '2%',
        '@media (max-width:570px)': {
            margin: '2%',
        },
    },

    frameTitle: {
        color: '#fff',
        fontSize: '1.75rem',
        lineHeight: '1.5',
        textAlign: 'center',
        fontWeight: '500',
        paddingTop: '2%',
        paddingBottom: '2%',
        '@media (max-width:570px)': {
            paddingLeft: '1%',
            paddingRight: '1%',
            fontSize: '1.25rem',
            lineHeight: '1.25',
        },

    },

    margin: {
        paddingRight: '5%',
        paddingLeft: '5%',
        paddingTop: '1%'
    },

    text: {
        paddingTop: '1.5%',
        paddingBottom: '1%',
        '@media (max-width:570px)': {
            textAlign: 'justify'
        },

    },
    paragraph: {
      '@media (max-width:570px)': {
          textAlign: 'justify'
      },
    }
    
    }));   