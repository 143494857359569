import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';

import useStyles from '../pagesStyles/histoireDuSaiilStyles';

import Layout from '../components/layout';
import Hero from '../components/Hero/index';

const StoryPage = ({data}) => {

  const classes = useStyles();
  const md = data.markdownRemark.frontmatter;
  const phrase = md.phrase;

  const formattedText = phrase.split(`\n`).map((p) => (
      <Typography className={classes.paragraph} variant="body1" component="p">{p.replace(/\n/g, '<br/>')}</Typography>
  ))

  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>{md.SEOtitle}</title>
        <meta name="description" content={md.SEOdescription} />
      </Helmet>

      <Hero 
      image={md.coverImage.childImageSharp.gatsbyImageData}
      title={md.title}
      />
      <Box className={classes.margin}>
        <Typography className={classes.colorTextSecondary} component="h3">
            {md.subtitle}
        </Typography>
        <Box mt={3}>
          {formattedText}
        </Box>
        <Typography className={classes.colorTextSecondary} component="h3">
            {md.subtitle1}
        </Typography>
        <Typography className={classes.text} variant="body1" component="p">
            {md.phrase1}
        </Typography>
        <Typography className={classes.colorTextSecondary} component="h3">
            {md.subtitle2}
        </Typography>
        <Typography className={classes.text} variant="body1" component="p">
            {md.phrase2}
        </Typography>

        <Box className={classes.frameColor}>
          <Typography className={classes.frameTitle} component="p">
              {md.boxLine1}
          </Typography>
          <Typography className={classes.frameTitle} component="p">
              {md.boxLine2}
          </Typography>
          <Typography className={classes.frameTitle} component="p">
              {md.boxLine3}
          </Typography>
        </Box>

        <Typography className={classes.text} variant="body1" component="p">
            {md.paragraph}
        </Typography>
        <Typography className={classes.colorTextSecondary} component="h3">
            {md.subtitle3}
        </Typography>
        <Typography className={classes.text} variant="body1" component="p">
            {md.phrase3}                
        </Typography>
        <Typography className={classes.text} variant="body1" component="p">
            {md.phrase4}                
        </Typography>

      </Box>

    </Layout>
  )
}

export default StoryPage

export const storyContent = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/histoireDuSaiil.md/"}) {
      frontmatter {
        SEOtitle
        SEOdescription
        title
        subtitle
        phrase
        subtitle1
        phrase1
        subtitle2
        phrase2
        boxLine1
        boxLine2
        boxLine3
        paragraph
        subtitle3
        phrase3
        phrase4
        coverImage {
          childImageSharp {
              gatsbyImageData
          }
        }
      }
    }
  }
` 
